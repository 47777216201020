@import url(https://fonts.googleapis.com/css?family=Exo+2:200|Inria+Sans|Montserrat&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap"); */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Playfair Display", Georgia, serif;
  margin: 0 0 30px 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

.header {
  /* background-image: linear-gradient(#7bd5f5, #787ff6); */
  background-image: white;
  background-color: white;
}
.header-image {
  margin: auto;
  width: 20rem;
  display: flex;
}
.name-header {
  font-size: 1.5em;
  text-align: center;
  font-family: "Exo 2", sans-serif;
}
.title-header {
  text-align: center;
  font-family: "Exo 2", sans-serif;
}
/* .contact-button {
  background-color: #645e91;
} */

.skills {
  /* background-color: #645e91; */
  /* background-color: #5f82a4; */
}
img.col.skills-image {
  width: 1px;
  display: flex;
  justify-content: center;
  justify-items: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Playfair Display", Georgia, serif;
  margin: 0 0 30px 0;
}
.bold {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  font-size: 250px;
  color: #f0f0f0;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .bold {
    font-size: 60px;
  }
}
.intro-heading {
  margin-bottom: 3em;
}
.intro-heading h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.5em;
  color: #000;
  position: relative;
}
.intro-heading span {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 0em;
  margin-top: 10em;
  color: #bfbfbf;
  font-weight: 600;
}
.rotate {
  position: absolute;
  top: 0.4em;
  left: -50px;
}
.rotate .heading {
  font-family: "Karla", Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
  transform: rotate(90deg);
  transform-origin: left top 0;
  position: relative;
}
.rotate .heading:after {
  position: absolute;
  top: 8px;
  right: -40px;
  content: "";
  background: #000;
  width: 40px;
  height: 1px;
}

.js .animate-box {
  opacity: 0;
}

.contact-me {
  text-align: center;
  background-color: white;
}

/* @import url("https://fonts.googleapis.com/css2?family=Karla&family=Playfair+Display&display=swap"); */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Playfair Display", Georgia, serif;
  margin: 0 0 30px 0;
}

.bold {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  font-size: 250px;
  color: #f0f0f0;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .bold {
    display: none;
    font-size: 60px;
  }
}

.intro-heading {
  margin-bottom: 3em;
}

.intro-heading h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.5em;
  color: #000;
  position: relative;
}

.intro-heading span {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 0em;
  margin-top: 10em;
  color: #bfbfbf;
  font-weight: 600;
}

.rotate {
  position: absolute;
  top: 0.4em;
  left: -50px;
}

.rotate .heading {
  font-family: "Karla", Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
  transform: rotate(90deg);
  transform-origin: left top 0;
  position: relative;
}

.rotate .heading:after {
  position: absolute;
  top: 8px;
  right: -40px;
  content: "";
  background: #000;
  width: 40px;
  height: 1px;
}

.js .animate-box {
  opacity: 0;
}

@media screen and (max-width: 767.98px) {
  .cardM {
    width: 100%;
    margin: 0 auto;
    clear: both;
    flex-basis: unset;
  }
}
/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap"); */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Playfair Display", Georgia, serif;
  margin: 0 0 30px 0;
}
.bold {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  font-size: 250px;
  color: #f0f0f0;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .bold {
    font-size: 60px;
  }
}
.row-padded-bottom {
  margin-bottom: 4em;
}
.intro-heading {
  margin-bottom: 3em;
}
.intro-heading h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.5em;
  color: #000;
  position: relative;
}
.intro-heading span {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 0em;
  margin-top: 10em;
  color: #bfbfbf;
  font-weight: 600;
}
.rotate {
  position: absolute;
  top: 0.4em;
  left: -50px;
}
.rotate .heading {
  font-family: "Karla", Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
  transform: rotate(90deg);
  transform-origin: left top 0;
  position: relative;
}
.rotate .heading:after {
  position: absolute;
  top: 8px;
  right: -40px;
  content: "";
  background: #000;
  width: 40px;
  height: 1px;
}

.about-desc {
  padding-top: 3em;
}
.about-desc h2 {
  line-height: 1.3;
}
.about-desc h2 span {
  display: block;
  margin-bottom: 0;
  color: #000;
  font-size: 50px;
  font-family: "Playfair Display", Georgia, serif;
}
.about-desc .desc {
  padding-left: 6em;
  position: relative;
}
.about-desc .rotate {
  position: absolute;
  top: 7px;
  left: 30px;
}
.about-desc .rotate .heading {
  font-family: "Karla", Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
  transform: rotate(90deg);
  transform-origin: left top 0;
  position: relative;
}
.about-desc .rotate .heading:after {
  position: absolute;
  top: 8px;
  right: -40px;
  content: "";
  background: #000;
  width: 40px;
  height: 1px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.js .animate-box {
  opacity: 0;
}
.aboutimg {
  position: absolute;
  z-index: 2;

  margin-top: 8rem;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
#colorlib-services,
#colorlib-contact,
#colorlib-work,
#colorlib-blog,
#colorlib-testimony,
#colorlib-progress,
#colorlib-contact,
#colorlib-about {
  padding: 13.5em 0 0 0;
  clear: both;
  position: relative;
}
@media screen and (max-width: 768px) {
  #colorlib-services,
  #colorlib-contact,
  #colorlib-work,
  #colorlib-blog,
  #colorlib-testimony,
  #colorlib-progress,
  #colorlib-contact,
  #colorlib-about {
    padding: 5em 0;
  }
}

/* .nav-style {
  background-color: #ededed;
} */
a.navbar-brand {
  padding-left: 1rem;
}

a.nav-link {
  padding-right: 50rem;
}

nav {
  width: 100%;
  margin: 0 auto;
  background: #0c0f38;
  background: #5f82a4;
  background: whitesmoke;
  padding: 20px 0;
  box-shadow: 0 3px 2px #dedede;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  display: flex;
  text-decoration: none;
  color: black;
  font-weight: 800;
  margin: 0 10px;
}

.logo-image {
  width: 3rem;
}

@media screen and (max-width: 768px) {
  a.navbar-brand {
    padding-left: 0;
  }
}
.footer {
  /* background-color: #0c0f38; */
  /* background-color: #5f82a4; */
  color: black;
  text-align: center;
}
.social-icon {
  margin: 20px 10px;
}

