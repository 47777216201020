@import url("https://fonts.googleapis.com/css?family=Exo+2:200|Inria+Sans|Montserrat&display=swap");
* {
  padding: 0;
  margin: 0;
}

.header {
  /* background-image: linear-gradient(#7bd5f5, #787ff6); */
  background-image: white;
  background-color: white;
}
.header-image {
  margin: auto;
  width: 20rem;
  display: flex;
}
.name-header {
  font-size: 1.5em;
  text-align: center;
  font-family: "Exo 2", sans-serif;
}
.title-header {
  text-align: center;
  font-family: "Exo 2", sans-serif;
}
/* .contact-button {
  background-color: #645e91;
} */
